export default function track(event, debug = false) {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || []

    if (debug) {
      // event.event is the event name
      console.info(`Event Tracking: tracking ${event.event}`)
    }
    dataLayer.push(event)
  }
}
