import { useState, useCallback } from "react"
import Link from "next/link"
import cn from "classnames"
import Image from "components/Image"
import Modal from "components/Modal"
import useModal from "utilities/useModal"
import getStoryblokLink from "utilities/getStoryblokLink"
import useWindowSize from "utilities/useWindowSize"
import richText from "utilities/richText"
import Placeholder from "public/assets/team-member-placeholder.svg"
import Arrow from "public/assets/pawprint-arrow.svg"
import Phone from "public/assets/team-member-phone.svg"
import Email from "public/assets/team-member-mail.svg"
import track from "utilities/track"
import slugify from "utilities/slugify"

function MemberModal({ name, title, phone, email, image, bio, eom, _uid }) {
  const modalProps = useModal(`${slugify(name)}-${_uid.slice(0, 6)}`)

  return (
    <Modal {...modalProps}>
      <div className="flex flex-col sm:flex-row gap-4 sm:gap-8">
        <div className="w-[186px] lg:w-[264px]">
          {image.filename ? (
            <Image
              src={image.filename}
              alt={image?.alt}
              width={264}
              height={264}
              className="aspect-square min-w-[186px] lg:min-w-[264px] object-cover"
            />
          ) : (
            <Placeholder
              className={cn("aspect-square w-[186px] lg:w-[264px]", {
                "text-green-sea": !eom,
                "text-green-apple": eom,
              })}
            />
          )}
        </div>
        <div>
          <h2 className="text-m1 lg:text-l1 pb-2">{name}</h2>
          <h3 className="text-s1 font-medium uppercase pb-2">{title}</h3>
          <div className="flex gap-4 pb-3 w-max">
            <ContactLinks {...{ phone, email }} />
          </div>
          {bio ? (
            <div className="sm:max-h-72 overflow-y-auto pr-4 prose-p:pb-4 last:prose-p:pb-0">{richText(bio)}</div>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

function MemberCard({ name, title, phone, email, image, eom, _uid, visible }) {
  const trackOpenModal = () => track({ event: "open_team_member_modal", team_member_title: title })

  return (
    <div
      className={cn(
        "max-w-[186px] shadow-xl hover:shadow-2xl rounded-3xl bg-white mx-3 my-1 transform transition-all duration-500 hover:-translate-y-1 flex flex-col",
        {
          "self-end": eom,
          "translate-y-0 opacity-1": !eom && visible,
          "translate-y-10 opacity-0": !eom && !visible,
        }
      )}
    >
      {eom ? (
        <div className="bg-green-apple text-black text-s1 uppercase pt-3 pb-2 px-4 text-center font-bold rounded-t-3xl">
          Employee of the month
        </div>
      ) : null}
      <Link
        href={`#${slugify(name)}-${_uid.slice(0, 6)}`}
        onClick={() => trackOpenModal()}
        className="aspect-square w-[186px]"
      >
        {image.filename ? (
          <Image
            src={image.filename}
            alt={image?.alt}
            width={186}
            height={186}
            className="aspect-square min-w-[186px] object-cover"
          />
        ) : (
          <Placeholder
            className={cn({
              "text-green-sea": !eom,
              "text-green-apple": eom,
            })}
            width={186}
            height={186}
          />
        )}
      </Link>
      <div className="p-4 flex flex-col h-full">
        <h3 className="text-m1 text-center leading-6 pt-2 pb-2.5 hover:underline">
          <Link href={`#${slugify(name)}-${_uid.slice(0, 6)}`} onClick={() => trackOpenModal()}>
            {name}
          </Link>
        </h3>
        <h4 className="text-s1 font-medium uppercase text-center pb-2.5">{title}</h4>
        <div className="flex justify-between items-end grow">
          <ContactLinks {...{ phone, email }} />
        </div>
      </div>
    </div>
  )
}

export default function TeamMemberSection({ blok: { team_members, employee_of_the_month, title } }) {
  const { width } = useWindowSize()
  const visibleSlides = useCallback(() => {
    if (
      (width >= 1024 && team_members.length >= 4 && !employee_of_the_month) ||
      (width >= 1536 && team_members.length >= 4)
    )
      return 4
    if (width >= 1280 && team_members.length >= 3) return 3
    if (width >= 640 && team_members.length >= 2) return 2
    return 1
  }, [team_members, employee_of_the_month, width])

  const [currentSlide, setSlide] = useState(0)

  // Render nothing for storyblok preview
  if (!team_members?.length) return null

  const slideWidth = 210

  function moveSlidesLeft() {
    currentSlide !== 0
      ? setSlide(currentSlide - slideWidth)
      : setSlide(team_members.length * slideWidth - visibleSlides() * slideWidth)
    track({ event: "click_team_member_carousel_arrow", direction: "left" })
  }

  function moveSlidesRight() {
    currentSlide <= (team_members.length - 1 - visibleSlides()) * slideWidth
      ? setSlide(currentSlide + slideWidth)
      : setSlide(0)
    track({ event: "click_team_member_carousel_arrow", direction: "right" })
  }

  return (
    <>
      {team_members.map((member) => (
        <MemberModal {...member} key={member._uid} />
      ))}
      <section className="lg:flex bg-gray-light">
        <div
          className={cn("mx-auto items-center flex flex-col w-full py-12 relative", {
            "max-w-4xl": visibleSlides() < 4,
            "max-w-6xl": visibleSlides() === 4,
            "basis-2/3": employee_of_the_month,
          })}
        >
          {title ? <h2 className="text-l1 lg:text-l2 pb-12">{title}</h2> : null}
          <div className="flex justify-center relative overflow-hidden w-full pb-3">
            <div
              className="flex items-center sm:items-stretch transition-all duration-700"
              style={{
                transform: `translateX(-${currentSlide}px)`,
                width: `${slideWidth * visibleSlides()}px`,
              }}
              aria-live="off"
            >
              {team_members.map((member, idx) => {
                const offset = currentSlide / slideWidth
                const range = offset + visibleSlides()
                const visible = idx < range && idx >= offset

                return <MemberCard {...{ ...member, visible }} key={member._uid} />
              })}
            </div>
          </div>
          {visibleSlides() < team_members.length ? (
            <div>
              <button onClick={moveSlidesLeft} aria-label="Previous Slide">
                <Arrow className="carousel-arrow absolute left-0 bottom-48 rotate-180 scale-75 sm:scale-100" />
              </button>
              <button onClick={moveSlidesRight} aria-label="Next Slide">
                <Arrow className="carousel-arrow absolute right-0 bottom-48 scale-75 sm:scale-100" />
              </button>
            </div>
          ) : null}
        </div>
        {employee_of_the_month ? (
          <>
            <div className="bg-star basis-1/3 flex justify-center pt-12 pb-20">
              <MemberCard {...employee_of_the_month} eom />
            </div>
            <MemberModal {...employee_of_the_month} eom />
          </>
        ) : null}
      </section>
    </>
  )
}

function ContactLinks({ phone, email }) {
  return (
    <>
      {getStoryblokLink(phone) ? (
        <div className="flex gap-1 items-center mx-auto">
          <Phone />
          <Link href={getStoryblokLink(phone)} className="uppercase underline hover:no-underline text-s1">
            Call
          </Link>
        </div>
      ) : null}
      {getStoryblokLink(email) ? (
        <div className="flex gap-1 items-center mx-auto">
          <Email />
          <Link href={getStoryblokLink(email)} className="uppercase underline hover:no-underline text-s1">
            Email
          </Link>
        </div>
      ) : null}
    </>
  )
}
